<template>
    <div>
        <div class="content">
            <div class="index index-70" :class="{ 'opened': (currentId == -1) }">
                <div class="card" style="">
                    <div class="row">
                        <div class="col-sm-6"><h5>Disponibilità istruttore</h5></div>
                        <div class="col-sm-6 text-right"><b-button size="sm" variant="primary" @click="duplicateWeekAvailability()" v-if="isEmptyWeek()">Duplica le disponibilità dalla settimana precedente</b-button></div>
                    </div>
                    <br>
                    <div v-if="showError">
                        <h5 style="color: red">Attenzione: alcune prenotazioni sono fuori disponibilità</h5>
                    </div>
                    <FullCalendar ref="fullCalendar" class='demo-app-calendar' :options='calendarOptions'>
                        <template v-slot:eventContent='arg'>
                            <div v-if="arg.event.extendedProps.type!='booking'">
                                <b>{{ arg.timeText }}</b>
                            </div>
                            <div v-else>
                                <span style="font-size:10px;white-space: nowrap !important;">
                                    {{ arg.event.title }}
                                </span>
                            </div>
                        </template>
                    </FullCalendar>
                </div>
            </div>

            <div class="editor editor-small" :class="{ 'opened': (currentId >= 0) }">
                <div class="top-editor">
                    <b-button variant="link" @click="closeEditor" :disabled="this.$store.state.loading">
                        <b-icon icon="x"/>
                        Chiudi
                    </b-button>
                </div>
                <div v-if="currentId > 0">
                    <instructor-availability-edit
                        v-on:closed-editor="closeEditor()"
                        v-on:deleted-instructor-availability="deletedInstructorAvailabilities()"
                        v-on:updated-instructor-availability="updatedInstructorAvailabilities()"
                        :instructor-availability-id="currentId"/>
                </div>
                <div v-else-if="currentId == 0">
                    <instructor-availability-create
                        v-on:closed-editor="closeEditor()"
                        :instructor-id="instructorId"/>
                </div>

            </div>

        </div>

    </div>

</template>

<script>
import InstructorAvailabilityDataService from "@/components/instructor_availability/InstructorAvailabilityDataService";
import BookingDataService from "@/components/booking/BookingDataService";
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import itLocale from '@fullcalendar/core/locales/it';
import InstructorAvailabilityCreate from "@/components/instructor_availability/InstructorAvailabilityCreate";
import InstructorAvailabilityEdit from "@/components/instructor_availability/InstructorAvailabilityEdit";
import moment from 'moment';

export default {
    name: "instructor-availabilities-index",
    components: {InstructorAvailabilityCreate, InstructorAvailabilityEdit, FullCalendar},
    data() {
        return {
            currentId: -1,
            edit: false,
            showError: false,
            calendarApi: null,
            currentWeekEvents: [],
            instructorId: null,
            calendarOptions: {
                plugins: [
                    dayGridPlugin,
                    timeGridPlugin,
                    interactionPlugin // needed for dateClick
                ],
                headerToolbar: {
                    left: 'prev,next today',
                    center: 'title',
                    right: 'dayGridMonth,timeGridWeek,timeGridDay'
                },
                slotMinTime: "08:00:00",
                slotMaxTime: "23:00:00",
                allDaySlot: false,
                contentHeight: window.innerHeight - 350,
                initialView: 'timeGridWeek',
                nowIndicator: true,
                editable: true,
                selectable: true,
                selectMirror: true,
                dayMaxEvents: true,
                weekends: true,
                locale: itLocale,
                //timeZone: 'UTC',
                slotEventOverlap: true,
                events: this.retrieveInstructorAvailabilities,
                eventDrop: this.move_event,
                select: this.new_event,
                eventClick: this.edit_event,
                eventResize: this.resize_event

            }
        };
    },
    props: ['propInstructorId'],
    methods: {

        loadInstructorAvailabilities() {
            let calendarApi = this.$refs.fullCalendar.getApi();
            calendarApi.refetchEvents();
        },

        new_event(selectInfo) {

            //let calendarApi = selectInfo.view.calendar
            this.calendarApi.unselect() // clear date selection
            let item = {
                id: null,
                dateStart: selectInfo.startStr,
                dateEnd: selectInfo.endStr,
                instructorId: this.instructorId
            }
            //console.log(item);
            InstructorAvailabilityDataService.create(item).then(response => {
                this.calendarApi.addEvent({
                    id: response.data.id,
                    title: '',
                    start: selectInfo.startStr,
                    end: selectInfo.endStr,
                    allDay: selectInfo.allDay,
                    type: 'availability',
                    backgroundColor: 'rgba(0, 105, 217, 0);'
                });
            })
                .catch(e => {
                    console.log(e);
                });
        },

        edit_event(clickInfo) {
            let start = clickInfo.event.start.getHours() + ":" + clickInfo.event.start.getMinutes();
            let end = clickInfo.event.end.getHours() + ":" + clickInfo.event.end.getMinutes();
            let date = clickInfo.event.start.toLocaleDateString('it-IT');
            //console.log("DELETE")
            if (clickInfo.event.extendedProps.type == "booking") {
                if (confirm(`Vuoi cancellare la prenotazione dalle ${start} alle ${end} del ${date}?`)) {
                    BookingDataService.delete(clickInfo.event.id).then(response => {
                        clickInfo.event.remove();
                    })
                        .catch(e => {
                            console.log(e);
                        });
                }
            } else {
                this.hide_arrow();
                this.currentId = clickInfo.event.id
                clickInfo.event.setExtendedProp("selezionato", "true");
                if (clickInfo.event.startEditable) {
                    console.log('d')
                }
            }

        },

        resize_event(clickInfo) {
            this.showError = false;
            let payload = {
                dateStart: clickInfo.event.startStr,
                dateEnd: clickInfo.event.endStr
            }

            if (clickInfo.event.extendedProps.type == "availability") {
                InstructorAvailabilityDataService.update(clickInfo.event.id, payload).then(response => {
                    this.showError = response.data.are_bookings_inside == false;
                })
                    .catch(e => {
                        console.log(e);
                    });
            }
        },

        move_event(clickInfo) {
            this.showError = false;
            let payload = {
                dateStart: clickInfo.event.startStr,
                dateEnd: clickInfo.event.endStr
            }
            if (clickInfo.event.extendedProps.type == "availability") {
                InstructorAvailabilityDataService.update(clickInfo.event.id, payload).then(response => {
                    this.showError = response.data.are_bookings_inside == false;
                })
                    .catch(e => {
                        console.log(e);
                    });
            }
        },

        duplicateWeekAvailability() {
            let currentDate = this.calendarApi.getDate();
            let previousDateWeek = moment(currentDate).subtract(7, 'days');
            let startDate = moment(previousDateWeek).startOf('week')
            let endDate = moment(previousDateWeek).endOf('week');
            console.log(startDate.format(), endDate.format());

            InstructorAvailabilityDataService.getAllByInstructor(this.instructorId, startDate.format(), endDate.format())
                .then(response_availabilities => {
                    let i = 0;
                    if (response_availabilities.data.length > 0) {
                        for (let item of response_availabilities.data) {
                            item.id = null;
                            item.dateStart = moment(item.dateStart).add(7, 'days').format();
                            item.dateEnd = moment(item.dateEnd).add(7, 'days').format();
                            InstructorAvailabilityDataService.create(item)
                                .then(response => {
                                    i++;
                                    if (i == response_availabilities.data.length) this.calendarApi.refetchEvents();
                                });
                        }
                    } else alert('La settimana precedente non contiene nessuna disponibilità');

            });
        },

        isEmptyWeek() {
            return this.currentWeekEvents.length == 0;
        },

        retrieveInstructorAvailabilities(fetchInfo, successCallback, failureCallback) {
            let events = [];
            InstructorAvailabilityDataService.getAllByInstructor(this.instructorId, fetchInfo.startStr, fetchInfo.endStr).then(response_availabilities => {
                BookingDataService.getAllByInstructor(this.instructorId).then(response_bookings => {

                    this.currentWeekEvents = [];
                    this.calendarApi.removeAllEvents();

                    let currentDate = this.calendarApi.getDate();
                    let weekStartDate = moment(currentDate).startOf('week')
                    let weekEndDate = moment(currentDate).endOf('week');


                    for (let item of response_availabilities.data) {
                        let startDate = new Date(item.dateStart);
                        let endDate = new Date(item.dateEnd);
                        let availability = {
                            id: item.id,
                            start: startDate,
                            end: endDate,
                            title: '',
                            backgroundColor: '#0069d9aa',
                            type: 'availability'
                        }
                        events.push(availability);
                        if (moment(availability.start).isAfter(weekStartDate, 'seconds') && moment(availability.start).isBefore(weekEndDate, 'seconds')) this.currentWeekEvents.push(item);
                    }


                    for (let item of response_bookings.data) {
                        let startDate = new Date(item.dateStart);
                        let endDate = new Date(item.dateEnd);
                        let event = {
                            id: item.id,
                            start: startDate,
                            end: endDate,
                            backgroundColor: '#ff0000aa',
                            editable: false,
                            type: 'booking'
                        }
                        if (item.student) event.title = item.student.firstname + ' ' + item.student.lastname;
                        events.push(event);
                    }

                    successCallback(events);

                })
                    .catch(e => {
                        console.log(e);
                    });
            })
                .catch(e => {
                    console.log(e);
                });
        },

        deletedInstructorAvailabilities() {
            this.loadInstructorAvailabilities()
            this.closeEditor();
        },

        updatedInstructorAvailabilities() {
            this.loadInstructorAvailabilities()
            this.closeEditor();
        },

        closeEditor() {
            this.hide_arrow();
            this.currentId = -1;
        },

        hide_arrow() {
            let calendarApi = this.$refs.fullCalendar.getApi();
            let events = calendarApi.getEvents();
            events.forEach(element => {
                element.setExtendedProp("selezionato", "false");
            });
        },
    },
    beforeMount() {
        if (this.propInstructorId == null) this.instructorId = this.$store.state.user.profileId;
        else this.instructorId = this.propInstructorId
    },
    mounted() {
        //moment.locale('it');
        this.calendarApi = this.$refs.fullCalendar.getApi();
    }
};
</script>

<style>
.myclassname {
    background: #0069d9;
    opacity: 0.2;
}

.vuecal__event {
    width: 96% !important;
    left: 2% !important;
    border: 0px solid transparent;
    margin: 0px auto;
    box-sizing: border-box;

    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;

}

.vuecal__event.lunch {
    background: repeating-linear-gradient(45deg, transparent, transparent 10px, #f2f2f2 10px, #f2f2f2 20px);
}

.blu {

    background-color: rgba(0, 105, 217, .2);
    color: #000;
    font-size: 13px;
}

.vuecal__event.blu.vuecal__event--focus {
    background-color: #0069d9;
    color: #fff;
}

.grey {
    background-color: #7e7e7e;
    background-color: rgba(126, 126, 126, .5);
    color: #fff;
    font-size: 13px;
}

.vuecal__event.grey.vuecal__event--focus {
    background-color: #7e7e7e;
    color: #fff;
}

.vuecal__view-btn {
    font-size: 1em;
}

.vuecal__title-bar {
    font-size: 1.1em;
    padding-top: 10px;
    padding-bottom: 10px;
}

@media screen and (min-width: 651px) {
    .editor-small.opened {
        top: 55vh;
    }

    .editor-small {
        max-width: 380px;
    }

}

</style>
