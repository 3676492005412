<template>
    <div class="card">
        <div v-if="!submitted">
            <b-form @submit.stop.prevent="saveInstructorAvailability">

                <b-form-group id="dateStart" label="Inizio" label-for="dateStart" description="">
                    <b-form-input v-model="instructorAvailability.dateStart"
                                  placeholder=""
                                  :state="validateState('dateStart')"
                                  aria-describedby="name-live-feedback"></b-form-input>
                    <b-form-invalid-feedback
                        id="name-live-feedback"
                    >Questo campo è obbligatorio.</b-form-invalid-feedback>
                </b-form-group>

                <b-form-group id="dateEnd" label="Fine" label-for="dateEnd" description="">
                    <b-form-input v-model="instructorAvailability.dateEnd"
                                  placeholder=""
                                  :state="validateState('dateEnd')"
                                  aria-describedby="name-live-feedback"></b-form-input>
                    <b-form-invalid-feedback
                        id="name-live-feedback"
                    >Questo campo è obbligatorio.</b-form-invalid-feedback>
                </b-form-group>

            </b-form>

            <div class="action-bar row">
                <div class="col-sm-8">
                    <button class="btn btn-success " @click="saveInstructorAvailability" :disabled="this.$store.state.loading"><b-icon icon="file-earmark-check"/> Salva</button>
                    <b-button variant="link" @click="closeEditor" :disabled="this.$store.state.loading"><b-icon icon="x"/> Chiudi</b-button>
                </div>
                <div class="col-sm-4 text-right">

                </div>
            </div>
            <div>
                <br>
                <b-alert variant="success" :show="message != ''" fade><b-icon icon="hand-thumbs-up"/> {{ message }}</b-alert>
                <b-alert variant="danger" :show="errorMsg != ''">{{ errorMsg }}</b-alert>
            </div>
        </div>

    </div>
</template>

<script>
import InstructorAvailabilityDataService from "./InstructorAvailabilityDataService";
import {required} from 'vuelidate/lib/validators'

export default {
    name: "instructor-availability-create",
    data() {
        return {
            instructorAvailability: {
                id: null,
                dateStart: '',
                dateEnd: '',
                instructorId: null
            },
            submitted: false,
            message: '',
            errorMsg: '',
        };
    },
    validations: {
        instructorAvailability: {
            dateStart: {
                required,
            },
            dateEnd: {
                required,
            }
        }
    },
    props: ['instructorId'],
    methods: {
        validateState(name) {
            const { $dirty, $error } = this.$v.instructorAvailability[name];
            return $dirty ? !$error : null;
        },
        saveInstructorAvailability() {
            this.$v.$touch()
            if (this.$v.$invalid) {
                this.submitted = false;
            } else {
                this.instructorAvailability.instructorId = this.instructorId;
                InstructorAvailabilityDataService.create(this.instructorAvailability)
                    .then(response => {
                        this.instructorAvailability.id = response.data.id;
                        this.submitted = true;
                        this.$emit('created-instructor-availability');
                    })
                    .catch(e => {
                        this.errorMsg = 'Non hai compilato tutti i campi';
                        console.log(e);
                    });
            }
        },

        closeEditor() {
            this.$emit('closed-editor');
        },
    }
};
</script>

<style>
.submit-form {
    max-width: 300px;
    margin: auto;
}
</style>
