<template>
    <div>
        <div v-if="currentInstructorAvailability" class="card">
            <b-form @submit="updateInstructorAvailability">
                <p></p>
                <b-form-group id="vehicleId" label="Abbina un mezzo a questa disponibilità (opzionale)" label-for="vehicleId" description="" v-if="vehicles.length > 0">
                    <b-form-select v-model="currentInstructorAvailability.vehicleId">
                        <option value="0">- Scegli un veicolo -</option>
                        <template v-for="vehicle in vehicles">
                            <option v-bind:value="vehicle.id" :key="vehicle.id">
                                {{ vehicle.name }}
                            </option>
                        </template>
                    </b-form-select>
                </b-form-group>
            </b-form>

            <div class="action-bar row">
                <div class="col-sm-8">
                    <button class="btn btn-success " @click="updateInstructorAvailability" :disabled="this.$store.state.loading"><b-icon icon="file-earmark-check"/> Salva</button>
                </div>
                <div class="col-sm-4 text-right">
                    <b-button variant="outline-danger" @click="deleteInstructorAvailability" :disabled="this.$store.state.loading"> <b-icon icon="trash"/></b-button>
                </div>
            </div>
            <div>
                <br>
                <b-alert variant="success" :show="message != ''" fade><b-icon icon="hand-thumbs-up"/> {{ message }}</b-alert>
                <b-alert variant="danger" :show="errorMsg != ''">{{ errorMsg }}</b-alert>
            </div>
        </div>

        <div v-else>
            <br/>
            <p>{{ (errorMsg) ? errorMsg : 'Please click on a InstructorAvailability...' }}</p>
        </div>
    </div>
</template>

<script>
import InstructorAvailabilityDataService from "./InstructorAvailabilityDataService";
import VehicleDataService from "@/components/vehicle/VehicleDataService";

export default {
    name: "instructor-availability-edit",
    data() {
        return {
            currentInstructorAvailability: null,
            day: null,
            startTime: null,
            endTime: null,
            message: '',
            errorMsg: '',
            vehicles: []
        };
    },
    props: ["instructorAvailabilityId"],
    methods: {
        getInstructorAvailability(id) {
            InstructorAvailabilityDataService.get(id)
                .then(response => {
                    this.currentInstructorAvailability = response.data;
                })
                .catch(e => {
                    this.errorMsg = e;
                    console.log(e);
                });
        },

        updateInstructorAvailability() {

            InstructorAvailabilityDataService.update(this.currentInstructorAvailability.id, this.currentInstructorAvailability)
                .then(response => {
                    this.message = 'Disponibilità istruttore aggiornata!';
                    this.$emit('updated-instructor-availability', this.currentInstructorAvailability);
                })
                .catch(e => {
                    console.log(e);
                });
        },

        deleteInstructorAvailability() {
            if(confirm(`Vuoi cancellare questa disponibilità?`)) {
                InstructorAvailabilityDataService.delete(this.currentInstructorAvailability.id)
                    .then(response => {
                        this.$emit('deleted-instructor-availability');
                    })
                    .catch(e => {
                        console.log(e);
                    });
            }

        },

        closeEditor() {
            this.$emit('closed-editor');
        }
    },
    mounted() {
        this.message = '';
        this.getInstructorAvailability(this.instructorAvailabilityId);
        VehicleDataService.getAll()
            .then(response => {
                this.vehicles = response.data
            })
            .catch(e => {
                console.log(e);
            });
    },

};
</script>

<style>
.action-bar {
    margin-top: 15px;
}
</style>
