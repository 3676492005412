<template>
    <div>
        <div class="">
            <div><search-bar @search-name="searchName($event)" position="embed"/>
            </div>
        </div>

        <div class="content" v-if="profiles.length > 0">
            <div class="index">
                <table class="table table-striped table-hover">
                    <thead>
                    <tr>
                        <th scope="col" width="40">#</th>
                        <th scope="col">Nome</th>
                        <th scope="col">Email</th>
                        <th scope="col" class="text-center">Stato</th>
                        <th scope="col" class="text-center"></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="profile in profiles"
                        :key="profile.id"
                        @click="addProfile(profile)"
                    >
                        <td>{{ profile.id }}</td>
                        <td>{{ profile.firstname }} {{ profile.lastname }}</td>
                        <td>{{ profile.user.email }}</td>
                        <td :class="{ 'text-success': (profile.status), 'text-muted': !profile.status }" class="text-center">
                            <b-icon :icon="(profile.status) ? 'check2' : 'x-circle'"/></td>
                        <td class="text-primary"><b-icon icon="plus"/></td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <!-- <b-button variant="link" @click="closeEditor" :disabled="this.$store.state.loading"><b-icon icon="x"/> Chiudi</b-button> -->
    </div>
</template>


<script>
import ProfileDataService from "@/components/profile/ProfileDataService";
import SearchBar from "@/components/layout/SearchBar";

export default {
    name: "profile-add",
    components: {SearchBar},
    data() {
        return {
            profiles: [],
        };
    },
    props: ["role","schoolId"],
    methods: {
        retrieveProfiles() {
            ProfileDataService.getAll()
                .then(response => {
                    this.profiles = response.data;
                })
                .catch(e => {
                    console.log(e);
                });
        },

        refreshList() {
            this.retrieveProfiles();
        },

        addProfile(profile) {
            const schoolId = this.schoolId || this.$store.state.schoolId;
            ProfileDataService.addRole(profile.id, this.role, schoolId)
                .then(response => {
                    if (response.data.error) alert('Questo profilo è già presente per questo ruolo.')
                    else this.$emit('added-profile');
                })
                .catch(e => {
                    console.log(e);
                });

        },

        searchName(name) {
            this.currentTeacher = null;
            this.currentId = -1;
            if (name == '') this.profiles = [];
            else ProfileDataService.findByName(name)
                .then(response => {
                    this.profiles = response.data;
                })
                .catch(e => {
                    console.log(e);
                });
        },

        searchReset() {
            this.profiles = [];
        },

        closeEditor() {
            this.$emit('closed-editor');
        }

    },
    mounted() {
        //this.retrieveProfiles();
    }
};
</script>

<style scoped>
    .index {
        width: 100%;
    }
</style>