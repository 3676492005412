<template>
    <div class="card" style="border-color: #ddd; border-radius: 5px; margin-bottom: 15px; background-color: #efefef">
        <br/>
        <dl class="row">
            <dt class="col-sm-3">Nome</dt>
            <dd class="col-sm-9">{{ profile.firstname }}</dd>

            <dt class="col-sm-3">Cognome</dt>
            <dd class="col-sm-9">{{ profile.lastname }}</dd>

            <dt class="col-sm-3">Data di nascita</dt>
            <dd class="col-sm-9">{{ profile.birthDate | moment }}</dd>
        </dl>
    </div>
</template>

<script>
import moment from 'moment';

export default {
    name: "profile-found-view",
    props: ['profile'],
    filters: {
        moment: function (date) {
            return moment(date).format('DD/MM/YYYY');
        },
    },
}
</script>

<style scoped>

</style>