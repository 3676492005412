<template>
    <div>
        <div class="row section-header" >
            <div class="col-md-3 section-title">
                <h3>Istruttori</h3>
            </div>

            <div class="col-md-6">
                <search-bar @search-name="searchName($event)"/>
            </div>

            <div class="col-md-3 text-right" style="">
            </div>
        </div>

        <div class="top-content ">
            <button class="btn btn-primary btn-add" @click="currentId = 0; currentInstructor = null">
                <b-icon icon="plus"/> Aggiungi Istruttore
            </button>
        </div>

        <div class="content">
            <div class="index index-30" :class="{ 'opened': (currentId == -1) }">
                <div class="card" style="overflow: hidden">
                    <table class="table table-striped table-hover">
                        <thead>
                        <tr>
                            <th scope="col" width="40"></th>
                            <th scope="col">Nome</th>
                            <th scope="col" class="text-center">Stato</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr :class="{ 'table-primary': instructor.id == currentId }"
                            v-for="instructor in instructors"
                            :key="instructor.id"
                            @click="setActiveInstructor(instructor)"
                        >
                            <td><img src="../../assets/user-Icon.jpg" class="rounded-circle" style="width: 30px"></td>
                            <td>{{ instructor.firstname }} {{ instructor.lastname }}</td>
                            <td :class="{ 'text-success': (instructor.status), 'text-muted': !instructor.status }" class="text-center">
                                <b-icon :icon="(instructor.status) ? 'check2' : 'x-circle'"/></td>
                        </tr>
                        </tbody>
                    </table>
                    <p v-if="this.instructors.length == 0">Ancora nessun istruttore inserito</p>
                </div>
            </div>

            <div class="editor editor-70" :class="{ 'opened': (currentId >= 0) }">
                <div class="top-editor">
                    <b-button variant="link" @click="closeEditor"  :disabled="this.$store.state.loading"><b-icon icon="x"/> Chiudi</b-button>
                </div>
                <div v-if="currentId > 0">
                    <instructor-edit :instructor-id="currentInstructor.id" :key="currentInstructor.id"
                                  v-on:updated-instructor="refreshCurrentInstructor($event)"
                                  v-on:deleted-instructor="refreshList()"
                                  v-on:closed-editor="closeEditor()"/>
                </div>
                <div v-else-if="currentId == 0">
                    <instructor-create
                        v-on:created-instructor="refreshList()"
                        v-on:closed-editor="closeEditor()"/>
                </div>
            </div>

        </div>




    </div>

</template>

<script>
import InstructorDataService from "./InstructorDataService";
import InstructorEdit from "@/components/instructor/InstructorEdit";
import InstructorCreate from "@/components/instructor/InstructorCreate";
import SearchBar from "@/components/layout/SearchBar";

export default {
    name: "instructors-index",
    components: {InstructorEdit, InstructorCreate, SearchBar},
    data() {
        return {
            instructors: [],
            currentInstructor: null,
            currentId: -1
        };
    },
    methods: {
        retrieveInstructors() {
            InstructorDataService.getAll()
                .then(response => {
                    this.instructors = response.data;
                })
                .catch(e => {
                    console.log(e);
                });
        },

        refreshList() {
            this.retrieveInstructors();
            this.currentInstructor = null;
            this.currentId = -1;
        },

        refreshCurrentInstructor(instructor) {
            this.instructors.forEach(function (item) {
                if (item.id == instructor.id) {
                    item.firstname = instructor.firstname;
                    item.lastname = instructor.lastname;
                    item.status = instructor.status;
                }
            });
            this.closeEditor();
            //this.currentInstructor = instructor;
            //this.currentId = instructor.id;
        },

        setActiveInstructor(instructor) {
            this.currentInstructor = instructor;
            this.currentId = instructor.id;
        },

        removeAllInstructors() {
            InstructorDataService.deleteAll()
                .then(response => {
                    this.refreshList();
                })
                .catch(e => {
                    console.log(e);
                });
        },

        searchName(name) {
            this.currentInstructor = null;
            this.currentId = -1;
            InstructorDataService.findByName(name)
                .then(response => {
                    this.instructors = response.data;
                })
                .catch(e => {
                    console.log(e);
                });
        },

        closeEditor() {
            this.currentInstructor = null;
            this.currentId = -1;
        }
    },
    mounted() {
        this.retrieveInstructors();
    }
};
</script>

<style scoped>
.editor {
    max-width: 950px;
}
</style>
