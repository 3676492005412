import http from "../../helpers/http-common";

class InstructorDataService {
    getAll() {
        return http.get("/instructors");
    }

    get(id) {
        return http.get(`/instructors/${id}`);
    }

    create(data) {
        return http.post("/instructors", data);
    }

    update(id, data) {
        return http.put(`/instructors/${id}`, data);
    }

    archive(id) {
        return http.get(`/instructors/archive/${id}`);
    }

    delete(id) {
        return http.delete(`/instructors/${id}`);
    }

    deleteAll() {
        return http.delete('/instructors');
    }

    findByName(name) {
        return http.get(`/instructors?name=${name}`);
    }
}

export default new InstructorDataService();
