<template>
    <div v-if="currentInstructor">
        <instructor-availability-index :prop-instructor-id="currentInstructor.id" />

        <br>

        <div class="card">
            <b-form @submit="updateInstructor">

                <b-form-group id="firstname" label="Nome" label-for="firstname" description="">
                    <b-form-input v-model="currentInstructor.firstname" placeholder=""></b-form-input>
                </b-form-group>

                <b-form-group id="lastname" label="Cognome" label-for="lastname" description="">
                    <b-form-input v-model="currentInstructor.lastname" placeholder=""></b-form-input>
                </b-form-group>

                <b-form-group id="email" label="Email" label-for="email" description="">
                    <b-form-input v-model="currentInstructor.email" placeholder="" type="email" disabled></b-form-input>
                </b-form-group>

                <b-form-group id="password" label="Password" label-for="password" description="Compila il campo solo se vuoi cambiare la password dell'utente, oppure lascialo vuoto.">
                    <b-form-input v-model="currentInstructor.password" placeholder="" type="password"></b-form-input>
                </b-form-group>

                Attivo: <toggle-button v-model="currentInstructor.status" :height="25"/>
            </b-form>

            <div class="action-bar row">
                <div class="col-sm-8">
                    <button class="btn btn-success " @click="updateInstructor" :disabled="this.$store.state.loading"><b-icon icon="file-earmark-check"/> Salva</button>
                </div>
                <div class="col-sm-4 text-right">
                    <b-button variant="outline-danger"
                              v-if="(hasRole('admin') || hasRole('owner'))"
                              @click="removeInstructor"
                              :disabled="this.$store.state.loading"> <b-icon icon="archive"/> Rimuovi dal ruolo</b-button>
                </div>
            </div>
            <div>
                <br>
                <b-alert variant="success" :show="message != ''" fade><b-icon icon="hand-thumbs-up"/> {{ message }}</b-alert>
                <b-alert variant="danger" :show="errorMsg != ''">{{ errorMsg }}</b-alert>
            </div>
        </div>



    </div>



    <div v-else>
        <br/>
        <p>{{ (errorMsg) ? errorMsg : 'Please click on a Instructor...' }}</p>
    </div>
</template>

<script>
import InstructorDataService from "./InstructorDataService";
import ProfileDataService from "@/components/profile/ProfileDataService";
import InstructorAvailabilityIndex from "@/components/instructor_availability/InstructorAvailabilityIndex";

export default {
    name: "instructor-edit",
    components: {InstructorAvailabilityIndex},
    data() {
        return {
            currentInstructor: null,
            message: '',
            errorMsg: ''
        };
    },
    props: ["instructorId"],
    methods: {
        getInstructor(id) {
            InstructorDataService.get(id)
                .then(response => {
                    this.currentInstructor = response.data;
                    this.currentInstructor.email = response.data.user.email;
                    this.currentInstructor.status = (this.currentInstructor.status == 1);
                })
                .catch(e => {
                    this.errorMsg = e;
                    console.log(e);
                });
        },

        updateInstructor() {
            InstructorDataService.update(this.currentInstructor.id, this.currentInstructor)
                .then(response => {
                    this.message = 'Informazioni salvate con successo!';
                    this.$emit('updated-instructor', this.currentInstructor);
                })
                .catch(e => {
                    console.log(e);
                });
        },

        removeInstructor() {
            if(confirm(`Vuoi rimuovere l'insegnante ${this.currentInstructor.firstname} ${this.currentInstructor.lastname}? Il profilo sarà comunque disponibile`)) {
                ProfileDataService.removeRole(this.currentInstructor.id, 'instructor')
                    .then(response => {
                        this.$emit('deleted-instructor');
                    })
                    .catch(e => {
                        console.log(e);
                    });
            }

        },

        archiveInstructor() {
            if(confirm(`Vuoi archiviare l'insegnante ${this.currentInstructor.firstname} ${this.currentInstructor.lastname}?`)) {
                InstructorDataService.archive(this.currentInstructor.id)
                    .then(response => {
                        this.$emit('deleted-instructor');
                    })
                    .catch(e => {
                        console.log(e);
                    });
            }

        },

        deleteInstructor() {
            if(confirm(`Vuoi cancellare l'insegnante ${this.currentInstructor.firstname} ${this.currentInstructor.lastname}?`)) {
                InstructorDataService.delete(this.currentInstructor.id)
                    .then(response => {
                        this.$emit('deleted-instructor');
                    })
                    .catch(e => {
                        console.log(e);
                    });
            }

        },

        closeEditor() {
            this.$emit('closed-editor');
        }
    },
    mounted() {
        this.message = '';
        this.getInstructor(this.instructorId);
    }
};
</script>

<style>
.action-bar {
    margin-top: 15px;
}
</style>
